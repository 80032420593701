export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "nl": {
        "codePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "verifyCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code verifiëren"])},
        "dag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dag"])},
        "dagen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dagen"])},
        "mfaSetup": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-Factor Authenticatie instellen"])},
          "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["De beheerder heeft Multi-Factor Authenticatie(MFA) ingesteld als verplichting om in te loggen. Volg de stappen hieronder om MFA in te stellen of download <a href=\"", _interpolate(_named("handleidingUrl")), "\" target=\"_blank\">hier</a> de gebruikershandleiding"])},
          "stap": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Stap ", _interpolate(_named("nr")), ":"])},
          "stap1": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download een authenticator app"])},
            "uitleg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met MFA ben je verplicht om na je wachtwoord een code in te vullen uit een van de authenticator apps (Google Authenticator, Microsoft Authenticator, Authy)."])},
            "uitleg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download en installeer één van de bovenstaande apps"])}
          },
          "stap2": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan de QR code"])},
            "uitleg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open de app, druk op het \"+\" icoon en scan de code"])},
            "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan je de code niet scannen?"])},
            "manualCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De volgende code kan handmatig worden toegevoegd aan de app:"])}
          },
          "stap3": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code invoeren"])},
            "uitleg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na het scannen van de QR code kan de code van de app hier worden ingevoerd."])}
          },
          "succes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je hebt succesvol MFA ingesteld! Je wordt over enkele seconden doorgestuurd..."])},
          "succesKlik1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of klik"])},
          "succesKlikHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])},
          "succesKlik2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["om doorgestuurd te worden"])}
        },
        "mfaVerify": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-Factor Authenticatie (MFA)"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestig dat jij dit bent door middel van de code in je authenticator app."])},
          "rememberme": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Deze computer ", _interpolate(_named("aantalDagenText")), " vertrouwen"])}
        },
        "error": {
          "mfaSuccesNoLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je hebt succesvol MFA ingesteld, maar bent nog niet ingelogd."])},
          "wachtwoordVerlopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je bedrijf heeft een wachtwoordbeleid waarmee wachtwoorden beperkte tijd geldig zijn."])},
          "wachtwoordOngeldig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De beheerder heef je wachtwoord als ongeldig verklaard en moet dus worden aangepast."])},
          "emailVerstuurd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is een e-mail verstuurd om het wachtwoord aan te passen."])},
          "terugNaarInloggen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar inloggen"])},
          "mfaAlIngesteld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je hebt MFA al ingesteld!"])},
          "geenCodeIngevuld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je hebt nog geen code ingevuld."])},
          "codeIncorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De code was incorrect!"])},
          "codeNietGeverifieerd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De code is niet geverifieerd: "])},
          "teVeelIncorrectePogingen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te veel mislukte pogingen. Neem contact op met de LEF-beheerder voor ondersteuning."])}
        }
      },
      "fr": {
        "codePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "verifyCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier le code"])},
        "dag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jour"])},
        "dagen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jours"])},
        "mfaSetup": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurer l'authentification multifacteur"])},
          "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["L'administrateur a configuré l'authentification multifacteur (MFA) comme condition requise pour se connecter. Suivez les étapes ci-dessous pour configurer MFA ou téléchargez le manuel d'utilisation <a href=\"", _interpolate(_named("handleidingUrl")), "\" target=\"_blank\">ici</a>"])},
          "stap": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Étape ", _interpolate(_named("nr")), ":"])},
          "stap1": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger une application d'authentification"])},
            "uitleg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec MFA, vous devez saisir un code de l'une des applications d'authentification (Google Authenticator, Microsoft Authenticator, Authy) dans votre mot de passe."])},
            "uitleg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez et installez l'une des applications ci-dessus"])}
          },
          "stap2": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scannez le code QR"])},
            "uitleg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrez l'application, appuyez sur l'icône \"+\" et scannez le code"])},
            "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas scanner le code ?"])},
            "manualCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code suivant peut être ajouté manuellement à l'application:"])}
          },
          "stap3": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez le code"])},
            "uitleg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après avoir scanné le code QR, le code de l'application peut être saisi ici"])}
          },
          "succes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez configuré avec succès MFA! Vous allez être redirigé dans quelques secondes..."])},
          "succesKlik1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ou cliquez"])},
          "succesKlikHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ici"])},
          "succesKlik2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour être redirigé"])}
        },
        "mfaVerify": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification multifacteur (MFA)"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmez qu'il s'agit bien de vous avec le code dans votre application d'authentification"])},
          "rememberme": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Faites confiance à cet ordinateur pendant ", _interpolate(_named("aantalDagenText"))])}
        },
        "error": {
          "mfaSuccesNoLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez configuré MFA avec succès, mais vous n'êtes pas encore connecté."])},
          "wachtwoordVerlopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre entreprise a une politique de mot de passe qui permet aux mots de passe d'être valides pendant un temps limité."])},
          "wachtwoordOngeldig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'administrateur a déclaré votre mot de passe invalide et doit être changé."])},
          "emailVerstuurd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un e-mail a été envoyé pour changer le mot de passe."])},
          "terugNaarInloggen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à la connexion"])},
          "mfaAlIngesteld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà configuré MFA !"])},
          "geenCodeIngevuld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore saisi de code."])},
          "codeIncorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code était incorrect!"])},
          "codeNietGeverifieerd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code n'a pas été vérifié: "])},
          "teVeelIncorrectePogingen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trop de tentatives échouées. Veuillez contacter l’administrateur LEF pour obtenir de l’aide."])}
        }
      },
      "de": {
        "codePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
        "verifyCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code verifizieren"])},
        "dag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
        "dagen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage"])},
        "mfaSetup": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einrichten der Multi-Faktor-Authentifizierung"])},
          "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der Administrator hat die Multi-Faktor-Authentifizierung (MFA) als Voraussetzung für die Anmeldung festgelegt. Folgen Sie den nachstehenden Schritten zur Einrichtung der MFA oder laden Sie das Benutzerhandbuch <a href=\\\"", _interpolate(_named("handleidingUrl")), "\\\" target=\\\"_blank\\\">hier</a> herunter"])},
          "stap": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Schritt ", _interpolate(_named("nr")), ":"])},
          "stap1": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungs-App herunterladen"])},
            "uitleg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Zuge der MFA müssen Sie nach Ihrem Passwort einen Code aus einer der Authentifizierungs-Apps (Google Authenticator, Microsoft Authenticator, Authy) eingeben."])},
            "uitleg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden Sie eine der oben genannten Apps herunter und installieren Sie sie"])}
          },
          "stap2": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scannen Sie den QR-Code"])},
            "uitleg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnen Sie die App, drücken Sie das \"+\"-Symbol und scannen Sie den Code"])},
            "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Können Sie den Code nicht scannen?"])},
            "manualCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der folgende Code kann manuell in die App eingegeben werden:"])}
          },
          "stap3": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code eingeben"])},
            "uitleg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach dem Scannen des QR-Codes kann der Code der App hier eingegeben werden."])}
          },
          "succes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben die MFA eingerichtet! Sie werden in wenigen Sekunden weitergeleitet ..."])},
          "succesKlik1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oder klicken Sie"])},
          "succesKlikHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hier"])},
          "succesKlik2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zur Weiterleitung"])}
        },
        "mfaVerify": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-Faktor-Authentifizierung (MFA)"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen Sie, dass Sie es sind, indem Sie den Code in Ihrer Authenticator-App verwenden."])},
          "rememberme": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Diesem Computer ", _interpolate(_named("aantalDagenText")), " vertraut"])}
        },
        "error": {
          "mfaSuccesNoLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben die MFA erfolgreich eingerichtet, sind aber noch nicht eingeloggt."])},
          "wachtwoordVerlopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Ihrem Unternehmen gibt es eine Passwortrichtlinie, nach der Kennwörter nur eine begrenzte Zeit gültig sind."])},
          "wachtwoordOngeldig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Administrator hat Ihr Passwort für ungültig erklärt und es muss geändert werden."])},
          "emailVerstuurd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde eine E-Mail zur Änderung des Passworts versandt."])},
          "terugNaarInloggen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zum Login"])},
          "mfaAlIngesteld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben die MFA bereits eingerichtet!"])},
          "geenCodeIngevuld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben noch keinen Code eingegeben."])},
          "codeIncorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Code war falsch!"])},
          "codeNietGeverifieerd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Code ist nicht verifiziert: "])},
          "teVeelIncorrectePogingen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu viele fehlgeschlagene Versuche. Bitte kontaktieren Sie den LEF-Administrator für Unterstützung."])}
        }
      }
    }
  })
}