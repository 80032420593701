<template>
    <Loader v-if="loading"></Loader>
    <Bubble v-else>
        <div v-if="wachtwoordVerlopen" class="messageContainer">
            <p v-if="!isMFAIngesteld">{{ t('error.mfaSuccesNoLogin') }}</p>
            <p>{{ t('error.wachtwoordVerlopen') }}</p>
            <p>{{ t('error.emailVerstuurd') }}</p>
            <button @click="backToLogin" class="backToLogin">{{ t('error.terugNaarInloggen') }}</button>
        </div>
        <div v-else-if="wachtwoordOngeldig" class="messageContainer">
            <p v-if="!isMFAIngesteld">{{ t('error.mfaSuccesNoLogin') }}</p>
            <p>{{ t('error.wachtwoordOngeldig') }}</p>
            <p>{{ t('error.emailVerstuurd') }}</p>
            <button @click="backToLogin" class="backToLogin">{{ t('error.terugNaarInloggen') }}</button>
        </div>
        <div v-else-if="!isMFAIngesteld && !setupVerified" class="mfaContainer" id="mfa-container">
            <h2>{{ t('mfaSetup.title') }}</h2>
            <div id="intro" v-html="t('mfaSetup.intro', { handleidingUrl })"></div>
            <div id="downloadApp">
                <img class="stapIcon" :src="require('shared/assets/downloadAppIcon.svg')"/>
                <p class="stapNr">{{ t('mfaSetup.stap', { nr: 1 }) }} <span class="stapTitel">{{ t('mfaSetup.stap1.title') }}</span></p>
                <p class="stapUitleg">{{ t('mfaSetup.stap1.uitleg1') }}</p>
                <p class="stapUitleg">{{ t('mfaSetup.stap1.uitleg2') }}</p>
            </div>
            <hr />
            <div id="qrCode">
                <img :src="qrCodeSetupImageUrl" />
                <p class="stapNr">{{ t('mfaSetup.stap', { nr: 2 }) }} <span class="stapTitel">{{ t('mfaSetup.stap2.title') }}</span></p>
                <p class="stapUitleg">{{ t('mfaSetup.stap2.uitleg') }}</p>
                <p class="stapUitleg" v-if="!showSetupCode" @click="showSetupCode = true"><a href="#">{{ t('mfaSetup.stap2.manual') }}</a></p>
                <p class="stapUitleg" v-if="showSetupCode">{{ t('mfaSetup.stap2.manualCode') }} <span class="manualCode">{{ setupCode }}</span></p>
            </div>
            <hr />
            <div id="verify">
                <img class="stapIcon" :src="require('shared/assets/enterMFACodeIcon.svg')" />
                <p class="stapNr">{{ t('mfaSetup.stap', { nr: 3 }) }} <span class="stapTitel">{{ t('mfaSetup.stap3.title') }}</span></p>
                <p class="stapUitleg">{{ t('mfaSetup.stap3.uitleg') }}</p>
                <div id="inputs">
                    <MFACodeInput id="mfaCode" v-model="mfaCode" @keydown.enter="verifyLoginSetupCode" />
                    <button class="btn btn-primary" :disabled="isButtonDisabled || mfaCode.length !== 6" @click="verifyLoginSetupCode" id="verifySetupCode">{{ t('verifyCode') }}</button>
                </div>
            </div>
        </div>
        <div v-else-if="!isMFAIngesteld && setupVerified" class="mfaContainer">
            <p>{{ t('mfaSetup.succes') }}</p>
            <p>{{ t('mfaSetup.succesKlik1') }} <a href="#" @click="loginSuccess">{{ t('mfaSetup.succesKlikHere') }}</a> {{ t('mfaSetup.succesKlik2') }}</p>
        </div>
        <div v-else-if="isMFAIngesteld" class="mfaContainer" id="mfa-check">
            <h2>{{ t('mfaVerify.title') }}</h2>
            <p>{{ t('mfaVerify.text') }}</p>
            <MFACodeInput id="mfaCodeCheck" v-model="mfaCode" @keydown.enter="verifyLoginCode" />
            <button class="btn btn-primary" :disabled="isButtonDisabled || mfaCode.length !== 6" @click="verifyLoginCode" id="verifyCode">{{ t('verifyCode') }}</button>
            <Checkbox v-if="rememberMeDays > -1" :label="rememberMeDaysText" v-model="rememberMe" class="rememberme" />
        </div>
        <div v-if="tooManyFailedAttempts" class="tooManyFailedAttemptsMessage">{{ t('error.teVeelIncorrectePogingen') }}</div>
    </Bubble>
    <form style="display: none" method="POST" :action="acsPostData.action" ref="acsForm">
        <input type="hidden" name="IssuedFrom" :value="store.app.value" />
        <input type="hidden" name="ReturnUrl" :value="store.returnUrl.value" />
        <input type="hidden" name="JWT" :value="acsPostData.jwt" />
    </form>
    <ToastrComponent></ToastrComponent>
</template>

<i18n lang="yaml" src="./locale/MFACheck.yaml" />

<script lang="ts" setup>
import { ref, onMounted, nextTick, computed } from "vue"
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import axios from "axios";
import Loader from "@pf/vue3/Loader.vue";
import ToastrComponent from "@pf/vue3/Toastr.vue";
import Checkbox from "@pf/vue3/Checkbox.vue";
import { useToastr } from "platformfrontend/ts/toastr";
import Bubble from '../components/Bubble.vue';
import MFACodeInput from 'shared/vue/MFACodeInput.vue'

const router = useRouter();
const store = useStore();
const i18n = useI18n();
const { t } = i18n;
const toastr = useToastr();
const handleidingUrl = 'https://cdn.mijnlef.nl/handleidingen/MFA%20instellen%20voor%20LEF%20gebruikers.pdf';

const loading = ref<boolean>(true);
const mfaJWT = ref<string | null>("");
const isMFAIngesteld = ref<boolean>(false);
const setupVerified = ref<boolean>(false);
const setupCode = ref<string>("");
const rememberMeDays = ref<number>(0);
const verifyAttempts = ref<number>(0);
const tooManyFailedAttempts = ref<boolean>(false);
const isButtonDisabled = ref<boolean>(false);

const wachtwoordVerlopen = ref<boolean>(false);
const wachtwoordOngeldig = ref<boolean>(false);

const showSetupCode = ref<boolean>(false);
const qrCodeSetupImageUrl = ref<string>("");
const gebruikerSecret = ref<string>("");
const mfaCode = ref<string>("");

const rememberMe = ref<boolean>(false);
const rememberMeDaysText = computed(() => {
    const aantalDagenText = rememberMeDays.value + ' ' + (rememberMeDays.value === 1 ? t('dag') : t('dagen'))
    return t('mfaVerify.rememberme', { aantalDagenText });
});

const acsForm = ref<HTMLFormElement | null>(null);
const acsPostData = ref({
    action: '',
    jwt: '' as string | null
});

onMounted(async () => {
    mfaJWT.value = store.mfaJWT.value;
    if (!mfaJWT.value) {
        router.push({ name: "login" });
        return;
    }

    await getMFAStatus();
    loading.value = false;
});

async function getMFAStatus() {
    var config = { headers: { "Content-Type": "application/json" } }
    await axios.post<MFAStatusResponse>("/api/identity/v1/mfa/login/status", mfaJWT.value, config).then(response => {
        if (response.status == 400 || response.status == 404) {
            router.push({ name: "login" });
            return;
        }

        isMFAIngesteld.value = response.data.IsMFAIngesteld
        setupCode.value = response.data.SetupCode ?? ""
        qrCodeSetupImageUrl.value = response.data.QrCodeSetupImageUrl ?? ""
        gebruikerSecret.value = response.data.GebruikerSecret ?? ""
        rememberMeDays.value = response.data.RememberMeDuurInDagen

    }).catch(() => {
        router.push({ name: "login" });
        return;
    })
}

async function verifyLoginSetupCode() {
  isButtonDisabled.value = true;

  let data = {
    Code: mfaCode.value,
    GebruikerSecret: gebruikerSecret.value,
    MFAToken: mfaJWT.value,
    Locale: i18n.locale.value,
    App: store.app.value
  } as VerifyLoginMFARequest;

  await axios.post<MFALoginResponse>("/api/identity/v1/mfa/login/setup/verify", data).then(response => {
    if (response.status == 200) {
      if (response.data.JWT && response.data.ACSUrl) {
        acsPostData.value = {
          jwt: response.data.JWT,
          action: response.data.ACSUrl
        };
        setupVerified.value = true;
        verifyAttempts.value = 0; // Reset attempts on success
        setTimeout(loginSuccess, 5000); // Gebruiker doorsturen
      }
      else if (response.data.WachtwoordVerlopen || response.data.WachtwoordOngeldig) {
        wachtwoordVerlopen.value = response.data.WachtwoordVerlopen;
        wachtwoordOngeldig.value = response.data.WachtwoordOngeldig;
      }
      else if (verifyAttempts.value >= 4) {
        tooManyFailedAttempts.value = true;
        verifyAttempts.value = 0;
        isButtonDisabled.value = false;
        return;
      }
      else {
        verifyAttempts.value++;
        toastr.error(t('error.codeIncorrect'));
        isButtonDisabled.value = false;
      }
      return;
    }
    else if (response.status == 409) {
      toastr.error(t('error.mfaAlIngesteld'));
      isButtonDisabled.value = false;
      return;
    }
    else if (response.status == 400) {
      toastr.error(t('error.geenCodeIngevuld'));
      isButtonDisabled.value = false;
      return;
    }

  }).catch(error => {
    verifyAttempts.value++;
    toastr.error(t('error.codeNietGeverifieerd') + error);
    isButtonDisabled.value = false;
  });
}


async function verifyLoginCode() {
    isButtonDisabled.value = true;

    let data = { 
        Code: mfaCode.value, 
        GebruikerSecret: null, 
        MFAToken: mfaJWT.value,
        Locale: i18n.locale.value,
        App: store.app.value,
        RememberMe: rememberMe.value
    } as VerifyLoginMFARequest;

    await axios.post("/api/identity/v1/mfa/login/verify", data).then(response => {
        if (response.status == 200) {
            if (response.data.JWT && response.data.ACSUrl) {
                acsPostData.value = {
                    jwt: response.data.JWT,
                    action: response.data.ACSUrl
                };
                loginSuccess();
                verifyAttempts.value = 0;
            }
            else if (response.data.WachtwoordVerlopen || response.data.WachtwoordOngeldig) {
                wachtwoordVerlopen.value = response.data.WachtwoordVerlopen;
                wachtwoordOngeldig.value = response.data.WachtwoordOngeldig;
            }
            else if (verifyAttempts.value >= 4) {
                tooManyFailedAttempts.value = true;
                verifyAttempts.value = 0;
                isButtonDisabled.value = false;
                return;
            }
            else {
                verifyAttempts.value++;
                toastr.error(t('error.codeIncorrect'));
                isButtonDisabled.value = false;
            }
            return;
        }
        else if (response.status == 400) {
            toastr.error(t('error.geenCodeIngevuld'));
            isButtonDisabled.value = false;
            return;
        }
    }).catch(error => {
        verifyAttempts.value++;
        toastr.error(t('error.codeNietGeverifieerd') + error);
        isButtonDisabled.value = false;
    });
}

function loginSuccess() {
    nextTick(() => {
        acsForm.value?.submit();
    });
}

function backToLogin() {
    router.push({ name: "login" });
}

interface MFAStatusResponse {
    IsMFAIngesteld: boolean;
    SetupCode: string | null;
    QrCodeSetupImageUrl: string | null;
    GebruikerSecret: string | null;
    RememberMeDuurInDagen: number
}

interface MFALoginResponse {
    JWT: string | null;
    ACSUrl: string | null;
    WachtwoordVerlopen: boolean;
    WachtwoordOngeldig: boolean;
}

interface VerifyLoginMFARequest {
    GebruikerSecret: string | null;
    Code: string | null;
    MFAToken: string | null;
    Locale: string | null;
    App: number;
    RememberMe: boolean | null;
}
</script>

<style lang="scss" scoped>

.messageContainer {
    padding: 15px;
    text-align: center;
    color: white;
    background-color: #337ab7;
    border: solid 1px #2e6da4;
    border-radius: 5px;

    p {
        margin: 0;
    }

    .backToLogin {
        color: white;
        background-color: transparent;
        text-decoration: underline;
        border: 0;
        margin-top: 10px;
        
        &:hover {
            cursor: pointer;
        }
    }
}

.mfaContainer {
    background-color: #FFF;
    color: #333;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
}

#mfa-container {

    .stapIcon {
        display: inline-block;
        height: 50px;
        width: 50px;
    }

    .stapNr {
        font-size: 14px;

        .stapTitel {
            font-size: 16px;
            font-weight:bold;
        }
    }

    .stapUitleg {

        .manualCode {
            font-size: 12px;
            font-style: italic;;
        }
    }

    .verifySetupCode {
        margin-top: 20px;
    }

    #verify {
        #inputs {
            margin-bottom: 20px;
        }
        
        #mfaCode {
            width: 100px;
            margin-right: 5px;
            display: inline;
        }
    }
}

#mfa-check {
    padding-bottom: 20px;
    padding-left: 40px;
    text-align: left!important;

    #mfaCodeCheck {
        display: inline;
        margin-right: 5px;
    }

    #verifyCode {
        height: 38px;
    }

    .rememberme {
        margin-top: 10px;
    }
}

.tooManyFailedAttemptsMessage {
  margin: 0 auto;
  background-color: #cf3939;
  color: white;
  padding: 16px;
  border-radius: 5px;
  transition: opacity 0.3s ease;
  opacity: 1;
  display: block;
  margin-top: 10px;
}

</style>

